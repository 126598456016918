"use strict";

$(function (e) {
  var url_root = window.location.protocol + "//" + window.location.host;
  $(document).on("click", "a[href*='http']:not(.new-window-ignore):not(a[href^='" + url_root + "']), a[href*='pdf']", function (e) {
    var t = $(this);
    var href = t.attr("href");
    var newwindow = true;

    if (newwindow) {
      window.open(href);
    } else {
      location.href = href;
    }

    return false;
  });
});