"use strict";

$(function () {
  var archives = $("#archives--dynamic li"),
      size = archives.length,
      show = 9,
      more = 3;
  $('#archives--dynamic li:lt(' + show + ')').show();
  $('#loadMore').click(function () {
    show = show + more <= size ? show + more : size;
    $('#archives--dynamic li:lt(' + show + ')').show("slow");
  });
  $('#archives--dynamic-modal li:lt(' + show + ')').show();
  $('#loadMore-modal').click(function () {
    show = show + more <= size ? show + more : size;
    $('#archives--dynamic-modal li:lt(' + show + ')').show("slow");
  });
});